<template>
    <div
        :key="'sampleSetTest' + sampleSetTest.id"
        class="mt-2 hover-cell"
        @click="$emit('edit', sampleSetTest)">
        <v-card>
            <v-card-text class="pa-2">
                {{ sampleSetTest.name }}
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        sampleSetTest: {
            type: Object,
            default: null,
        },
    },
};
</script>
<style scoped>
    .hover-cell {
        cursor: pointer;
    }
</style>
